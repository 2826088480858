.sinUpPage {
  width: 100%;
  height: 650px;
  background-image: url("../Images/bg-svg-f.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f8f8f8;

}
.SignUpPageComponents{
    display: flex;
    width: 80%;
    padding: 80px;
    margin: auto;

}
