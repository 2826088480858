.about{
    width: 500px;
    height:400px ;

}
.about button{
   width: 200px;
padding: 10px;
background-color: rgb(255, 149, 0);
border: 1px;
border-radius: 5px;
}