
.landingPage form input{
    width: 400px;
    height: 30px;
    margin: 5px 0px;
    border-radius: 3px;
}
.landingPageWrapper{
    padding: 30px ;
    
}
.button{
    width: 30px;
    height: 50px;
    padding: 10px;
    background-color: rgb(0, 26, 255);
    border: 1px;
    border-radius: 5px;}
.warning{
    margin: 0;
    color: red;
    text-align: left;
}